<template>
	<S2SForm title="App Version">
		<S2SCard wrap align-center fluid>
			<v-container class="text-right pa-0">
				<v-btn color="accent" @click="dialog = true">Add</v-btn>
				<v-dialog v-model="dialog" scrollable persistent max-width="500px" transition="dialog-transition">
					<v-card>
						<v-card-title>New App Version</v-card-title>
						<v-card-text>
							<v-form ref="form">
								<v-row>
									<v-col cols="12" md="6">
										<v-text-field
											v-model="newAppEntry.LatestVersionCode"
											:rules="[LatestVersionCode => !!LatestVersionCode || 'Latest Version Code is required']"
											label="Latest Version Code"
										></v-text-field>
									</v-col>
									<v-col cols="12" md="6">
										<v-text-field
											v-model="newAppEntry.LatestVersionName"
											:rules="[LatestVersionName => !!LatestVersionName || 'Latest Version Name is required']"
											label="Latest Version Name"
										></v-text-field>
									</v-col>
								</v-row>
								<v-row>
									<v-col cols="12" md="6">
										<v-text-field
											v-model="newAppEntry.MinimumVersionCode"
											:rules="[MinimumVersionCode => !!MinimumVersionCode || 'Minimum Version Code is required']"
											label="Minimum Version Code"
										></v-text-field>
									</v-col>
									<v-col cols="12" md="6">
										<v-text-field
											v-model="newAppEntry.MinimumVersionName"
											:rules="[MinimumVersionName => !!MinimumVersionName || 'Minimum Version Name is required']"
											label="Minimum Version Name"
										></v-text-field>
									</v-col>
								</v-row>
								<v-row>
									<v-col cols="12" md="6">
										<v-dialog ref="dialog" v-model="modal" :return-value.sync="newAppEntry.ActiveDate" persistent width="290px">
											<template v-slot:activator="{ on }">
												<v-text-field
													v-model="newAppEntry.ActiveDate"
													label="Active Date"
													prepend-icon="event"
													readonly
													v-on="on"
												></v-text-field>
											</template>
											<v-date-picker v-model="newAppEntry.ActiveDate" scrollable>
												<v-spacer></v-spacer>
												<v-btn text color="primary" @click="modal = false">Cancel</v-btn>
												<v-btn text color="primary" @click="$refs.dialog.save(newAppEntry.ActiveDate)">OK</v-btn>
											</v-date-picker>
										</v-dialog>
									</v-col>
									<v-col cols="12" md="6">
										<v-dialog ref="dialogTime" v-model="modalTime" :return-value.sync="newAppEntry.ActiveTime" persistent width="290px">
											<template v-slot:activator="{ on }">
												<v-text-field
													v-model="newAppEntry.ActiveTime"
													label="Active Time"
													prepend-icon="mdi-clock-outline"
													readonly
													v-on="on"
												></v-text-field>
											</template>

											<v-time-picker v-model="newAppEntry.ActiveTime" class="mt-2" ampm-in-title>
												<v-spacer></v-spacer>
												<v-btn text color="primary" @click="modalTime = false">Cancel</v-btn>
												<v-btn text color="primary" @click="$refs.dialogTime.save(newAppEntry.ActiveTime)">OK</v-btn>
											</v-time-picker>
										</v-dialog>
									</v-col>
								</v-row>
							</v-form>
						</v-card-text>
						<v-card-actions class="justify-end">
							<v-btn text @click="dialog = false">Cancel</v-btn>
							<v-btn color="accent" @click="submitAppVersion()">Create</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</v-container>
			<v-divider class="mt-4 mb-4"></v-divider>
			<v-data-table ref="dataTable" :headers="headers" :items="appVersions" :loading="status.loading" class="elevation-1">
				<template v-slot:item.ActiveDate="props">
					<v-menu v-model="dateModal[props.item.Id]" :close-on-content-click="false" transition="scale-transition" offset-y>
						<template v-slot:activator="{ on }">
							<v-text-field v-model="props.item.ActiveDate[0]" label="Active Date" readonly v-on="on" single-line></v-text-field>
						</template>
						<v-date-picker
							v-model="props.item.ActiveDate[0]"
							no-title
							@input="
								dateModal[props.item.Id] = false;
								updateVersion(props.item);
							"
						></v-date-picker>
					</v-menu>
				</template>
				<template v-slot:item.Time="props">
					<v-menu v-model="timeModal[props.item.Id]" :close-on-content-click="false" transition="scale-transition" offset-y>
						<template v-slot:activator="{ on }">
							<v-text-field v-model="props.item.ActiveDate[1]" label="Active Date" readonly v-on="on" single-line></v-text-field>
						</template>
						<v-time-picker
							v-model="props.item.ActiveDate[1]"
							class="mt-2"
							ampm-in-title
							@click:minute="
								timeModal[props.item.Id] = false;
								updateVersion(props.item);
							"
						></v-time-picker>
					</v-menu>
				</template>
				<template v-slot:item.LatestVersionCode="props">
					<v-edit-dialog persistent @save="updateVersion(props.item)">
						{{ props.item.LatestVersionCode }}
						<template v-slot:input>
							<v-text-field v-model="props.item.LatestVersionCode" label="Latest Version Code" single-line counter></v-text-field>
						</template>
					</v-edit-dialog>
				</template>
				<template v-slot:item.LatestVersionName="props">
					<v-edit-dialog persistent @save="updateVersion(props.item)">
						{{ props.item.LatestVersionName }}
						<template v-slot:input>
							<v-text-field v-model="props.item.LatestVersionName" label="Latest Version Name" single-line counter></v-text-field>
						</template>
					</v-edit-dialog>
				</template>
				<template v-slot:item.MinimumVersionCode="props">
					<v-edit-dialog persistent @save="updateVersion(props.item)">
						{{ props.item.MinimumVersionCode }}
						<template v-slot:input>
							<v-text-field v-model="props.item.MinimumVersionCode" label="Minimum Version Code" single-line counter></v-text-field>
						</template>
					</v-edit-dialog>
				</template>
				<template v-slot:item.MinimumVersionName="props">
					<v-edit-dialog persistent @save="updateVersion(props.item)">
						{{ props.item.MinimumVersionName }}
						<template v-slot:input>
							<v-text-field v-model="props.item.MinimumVersionName" label="Minimum Version Name" single-line counter></v-text-field>
						</template>
					</v-edit-dialog>
				</template>
			</v-data-table>
		</S2SCard>
	</S2SForm>
</template>

<script>
import Vue from "vue";
import { Utils } from "@/utils";

export default Vue.extend({
	name: "DeviceAppVersion",

	computed: {
		appVersions: function() {
			return this.$store.state.devices.appVersions;
		},
		status: function() {
			return this.$store.state.devices.status;
		}
	},

	data: function() {
		return {
			dialog: false,
			modal: false,
			modalTime: false,
			dateModal: {},
			timeModal: {},

			newAppEntry: {
				LatestVersionCode: null,
				LatestVersionName: null,
				MinimumVersionCode: null,
				MinimumVersionName: null,
				ActiveDate: this.getDate(),
				ActiveTime: "00:00:00"
			},

			headers: [
				{
					text: "Active Date",
					value: "ActiveDate",
					sortable: false
				},
				{
					text: "Active Time",
					value: "Time",
					sortable: false
				},
				{
					text: "Latest Version Code",
					value: "LatestVersionCode"
				},
				{
					text: "Latest Version Name",
					value: "LatestVersionName",
					sortable: false
				},
				{
					text: "Minimum Version Code",
					value: "MinimumVersionCode"
				},
				{
					text: "Minimum Version Name",
					value: "MinimumVersionName",
					sortable: false
				}
			]
		};
	},

	created: function() {
		this.$store.dispatch("devices/fetchAppVersion");
	},

	methods: {
		updateVersion(item) {
			item.ActiveDate = item.ActiveDate.join("T");
			this.$store.dispatch("devices/updateAppVersion", item);
		},
		getDate() {
			let date = Date.now();
			let d = new Date(date),
				month = "" + (d.getMonth() + 1),
				day = "" + d.getDate(),
				year = d.getFullYear();

			if (month.length < 2) month = "0" + month;
			if (day.length < 2) day = "0" + day;

			let historyDate = [year, month, day].join("-");
			return historyDate;
		},
		formatDate(val) {
			return Utils.formatText(val, Utils.TextType.DATE_TIME);
		},
		async submitAppVersion() {
			const form = this.$refs["form"];
			const valid = await form.validate();
			if (!valid) {
				return;
			} else {
				let dateTime = this.newAppEntry.ActiveDate + "T" + this.newAppEntry.ActiveTime;

				const appVersion = {
					LatestVersionCode: this.newAppEntry.LatestVersionCode,
					LatestVersionName: this.newAppEntry.LatestVersionName,
					MinimumVersionCode: this.newAppEntry.MinimumVersionCode,
					MinimumVersionName: this.newAppEntry.MinimumVersionName,
					ActiveDate: dateTime
				};
				// do call
				this.$store.dispatch("devices/createAppVersion", appVersion);
			}

			// reset form
			this.$refs.form.reset();

			this.dialog = false;
		}
	}
});
</script>
